import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { visuallyHidden } from '@mui/utils';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import serverApi from '../api/server'
import LinearProgress from '@mui/material/LinearProgress';

// const INDIVIDUAL_STAGES = {
//     1: 'Application Information',
//     2: 'Registered Address',
//     3: 'Trading Account Information',
//     4: 'Financial Information',
//     5: 'Banking Information',
//     6: 'Wallet Address',
//     7: 'Declaration',
//     8: 'complete',
//     10: 'complete'
// }
// const ENTITY_STAGES = {
//     1: 'Enterprise Verification',
//     2: 'Additional Information',
//     3: 'Trading Account',
//     4: 'Financial Information',
//     5: 'Banking Information',
//     6: 'Wallet Address',
//     7: 'Upload Documents',
//     9: 'UBO Verification',
//     10: 'Additional UBO',
//     8: 'Declaration',
//     11: 'KYC'
// }
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'applicant_name',
        numeric: false,
        disablePadding: false,
        label: 'Primary Appilcant',
    },
    // {
    //     id: 'type',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Type',
    // },
    // {
    //     id: 'current_stage',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Current Stage',
    // },
    // {
    //     id: 'completed_form',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Form',
    // },
    // {
    //     id: 'percentage_complete',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Percentage Complete',
    // },
    {
        id: 'updated_date',
        numeric: false,
        disablePadding: false,
        label: 'Date Joined',
    },
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
            <TableCell />
            {headCells.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'center' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                    ) : null}
                </TableSortLabel>
                </TableCell>
            ))}
            </TableRow>
        </TableHead>
    );
}

function Row(props) {
    const { row, handleClick, isItemSelected, setLoading } = props;
    const [open, setOpen] = React.useState(false);
    const [kycList, setKycList] = React.useState([]);
    const [emailStatus, setEmailStatus] = React.useState([]);
    // const [loading, setLoading] = React.useState(true)
    const getKYC = async() => {
        setLoading(true)
        try {
            const result = await serverApi.post('/get-entity-users-kyc/', {email: row.email})
            console.log(row.email)
            console.log(result)
            if(result.data) {
                let incompleteKycList = [...new Set(result.data.data)]
                // console.log(incompleteKycList)
                setKycList(incompleteKycList)
                setLoading(false)

            }
            setLoading(false)
            return result.data
            // console.log(result)
        }
        catch (err) {
            console.log(err)
            setLoading(false)
            return []
        }
    }
    React.useEffect(() => {
        getKYC()
    }, [row.email])
    const handleSendingKYCemail = async(email) => {
        setLoading(true)
        let emailSent = [...emailStatus]
        const result = await serverApi.post('/send-kyc-email/', {email: email})
        // console.log(result)
        if(result.data){
            if(result.data.status == 'success'){
                emailSent.push(email)
                setEmailStatus(emailSent)
            }
        }
        setLoading(false)
    }
    if(kycList && kycList.length === 0) {
        return null;
    }
    return (
        <React.Fragment>
            <TableRow 
                sx={{ '& > *': { borderBottom: 'unset' } }}
                hover
                onClick={(event) => handleClick(event, row.name)}
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.name}
                selected={isItemSelected}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        disabled={kycList && kycList.length < 1}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.applicant_name}</TableCell>
                {/* <TableCell align="left">{row.type}</TableCell>
                <TableCell align="center">{row.current_stage}</TableCell>
                <TableCell align="left">{row.type === 'individual' ? INDIVIDUAL_STAGES[row.current_stage] : ENTITY_STAGES[row.current_stage]}</TableCell>
                <TableCell align="center">{row.percentage_complete + '%'}</TableCell> */}
                <TableCell align="left">{moment(row.updated_date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                {/* {loading? <LinearProgress /> : null} */}
                <Box sx={{ margin: 1 }}>
                    <Table size="small" aria-label="purchases">
                    
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row" padding={'normal'}>Incomplete KYC Email</TableCell>
                            <TableCell align='left' padding={'normal'}></TableCell>
                            <TableCell align='left' padding={'normal'}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {kycList && kycList.map((email, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">{email}</TableCell>
                                <TableCell align='left'><Button variant="text" onClick={() => handleSendingKYCemail(email)}>Send Email</Button></TableCell>
                                <TableCell style={{color: 'green'}}>{emailStatus.indexOf(email) > -1 ? 'Email Sent' : ''}</TableCell>    
                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </Box>
                </Collapse>
            </TableCell>
            </TableRow>
        </React.Fragment>
    );
}  
function KYC({data}) {
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('updated_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchValue, setSearchValue] = React.useState('');
    const [loading, setLoading] = React.useState(true)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = data.map((n) => n.name);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const handleSearchValueOnChange = (e) => {
        let inputValue = (e.target.value).trim()
        setSearchValue(inputValue)
    }
    const handleClickClearSearchInput = () => {
        setSearchValue('')
    }
    return (
        <Box sx={{ width: '100%' }}>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
                sx={{position: 'absolute', right: 10, top: 80, height: '15px' }}
            />
            <Paper sx={{ width: '100%', mb: 2, marginTop: 6, paddingTop: 1 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200, margin: 1 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        value={searchValue}
                        onChange={(e) => handleSearchValueOnChange(e)}
                        placeholder="Search Email or Name"
                        inputProps={{ 'aria-label': 'search email address' }}
                        endAdornment={searchValue? 
                            <InputAdornment position="end">
                                <IconButton
                                    size='small'
                                    onClick={handleClickClearSearchInput}
                                    style={{borderRadius: 0}}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment> : <SearchIcon />}
                    />
                </Paper>
                <TableContainer>
                {loading? <LinearProgress /> : null}
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                        rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(data, getComparator(order, orderBy))
                        .filter(item => item.type == 'entity')
                        .filter(item => searchValue? item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.email.toLowerCase().includes(searchValue.toLowerCase()) : item)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const isItemSelected = isSelected(row.name);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <Row  key={labelId} setLoading={setLoading} isItemSelected={isItemSelected} handleClick={handleClick} row={row}/>
                                // <TableRow
                                //     hover
                                //     onClick={(event) => handleClick(event, row.name)}
                                //     role="checkbox"
                                //     aria-checked={isItemSelected}
                                //     tabIndex={-1}
                                //     key={row.name}
                                //     selected={isItemSelected}
                                // >
                                //     <TableCell
                                //         component="th"
                                //         id={labelId}
                                //         scope="row"
                                //         //padding="none"
                                //     >
                                //         {row.name}
                                //     </TableCell>
                                //     <TableCell align="left">{row.email}</TableCell>
                                //     <TableCell align="left">{row.applicant_name}</TableCell>
                                //     <TableCell align="left">{row.type}</TableCell>
                                //     <TableCell align="center">{row.current_stage}</TableCell>
                                //     <TableCell align="left">{row.type === 'individual' ? INDIVIDUAL_STAGES[row.current_stage] : ENTITY_STAGES[row.current_stage]}</TableCell>
                                //     <TableCell align="center">{row.percentage_complete + '%'}</TableCell>
                                //     <TableCell align="left">{moment(row.updated_date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</TableCell>
                                // </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                                >
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.filter(item => item.type == 'entity').length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export default KYC;