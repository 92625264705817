import React, {useEffect, useState} from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import serverApi from '../api/server'
import moment from 'moment';
import All from './All';
import Incomplete from './Incomplete';
import Complete from './Complete';
import KYC from './KYC';
import Unregistered from './Unregistered';
import KYCStatus from './KYCStatus';
const INDIVIDUAL_STAGES = {
    1: 'Application Information',
    2: 'Registered Address',
    3: 'Trading Account Information',
    4: 'Financial Information',
    5: 'Banking Information',
    6: 'Wallet Address',
    7: 'Declaration',
    8: 'complete',
    10: 'complete'
}
const ENTITY_STAGES = {
    1: 'Enterprise Verification',
    2: 'Additional Information',
    3: 'Trading Account',
    4: 'Financial Information',
    5: 'Banking Information',
    6: 'Wallet Address',
    7: 'Upload Documents',
    9: 'UBO Verification',
    10: 'Additional UBO',
    8: 'Declaration',
    11: 'KYC'
}
const columns = [
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'applicant_name', headerName: 'Primary Appilcant', width: 250 },
    { field: 'type', headerName: 'Type', width: 100 },
    { field: 'current_stage', headerName: 'Current Stage', width: 110 },
    { field: 'completed_form', headerName: 'Form', width: 200,
        valueGetter: (params) => {
            if(params.row.type === 'individual') {
                return INDIVIDUAL_STAGES[params.row.current_stage]
            } else {
                return ENTITY_STAGES[params.row.current_stage]
            }
        }
    },
    { field: 'updated_date', headerName: 'Date Joined', width: 200,
        valueGetter: (params) => moment(params.row.updated_date).fromNow()
    }
];

function Tables(props) {
    const [currentTab, setCurrentTab] = useState(0);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };
    // api call
    const getAllUserData = async() => {
        try {
            const result = await serverApi.get('/user-stages')
            console.log(result)
            return result.data
            // console.log(result)
        }
        catch (err) {
            alert(err)
            return []
        }
    }
    useEffect(() => {
        setLoading(true)
        getAllUserData().then(res => {
            setUserData(res.data);
            setLoading(false)
        })
    }, [])
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    value={currentTab} 
                    onChange={handleChange} 
                    variant="scrollable" 
                    allowScrollButtonsMobile
                    scrollButtons={true}
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                          '&.Mui-disabled': { opacity: 0.3 },
                        },
                      }}
                >
                    <Tab label="All Applicants" />
                    <Tab label="Incomplete Applicants" />
                    <Tab label="Complete Applicants" />
                    <Tab label="Unregistered Applicants" />
                    <Tab label="Incomplete KYC" />
                    <Tab label="All User KYC Status" />
                </Tabs>
            </Box>
            {currentTab === 0 && (
                <Box sx={{ p: 3 }}>
                    <All data={userData}/>
                </Box>
            )}
            {currentTab === 1 && (
                <Box sx={{ p: 3 }}>
                    <Incomplete data={userData}/>
                </Box>
            )}
            {currentTab === 2 && (
                <Box sx={{ p: 3 }}>
                    <Complete data={userData}/>
                </Box>
            )}
            {currentTab === 3 && (
                <Box sx={{ p: 3 }}>
                    <Unregistered/>
                </Box>
            )}
            {currentTab === 4 && (
                <Box sx={{ p: 3 }}>
                    <KYC data={userData}/>
                </Box>
            )}
            {currentTab === 5 && (
                <Box sx={{ p: 3 }}>
                    <KYCStatus />
                </Box>
            )}
            {/* <TabPanel value={value} index={0}>
                Item One
            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
            </TabPanel> */}
        </Box>
    );
}

export default Tables;