import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import serverApi from '../api/server'
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl';
// const INDIVIDUAL_STAGES = {
//     1: 'Application Information',
//     2: 'Registered Address',
//     3: 'Trading Account Information',
//     4: 'Financial Information',
//     5: 'Banking Information',
//     6: 'Wallet Address',
//     7: 'Declaration',
//     8: 'complete',
//     10: 'complete'
// }
// const ENTITY_STAGES = {
//     1: 'Enterprise Verification',
//     2: 'Additional Information',
//     3: 'Trading Account',
//     4: 'Financial Information',
//     5: 'Banking Information',
//     6: 'Wallet Address',
//     7: 'Upload Documents',
//     9: 'UBO Verification',
//     10: 'Additional UBO',
//     8: 'Declaration',
//     11: 'KYC'
// }
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
// function stableSort(array, comparator) {
//     const stabilizedThis = array.map((el, index) => [el, index]);
//     stabilizedThis.sort((a, b) => {
//         const order = comparator(a[0], b[0]);
//         if (order !== 0) {
//             return order;
//         }
//         return a[1] - b[1];
//     });
//     return stabilizedThis.map((el) => el[0]);
// }
const headCells = [
    {
        id: 'kyc_applicant_id',
        numeric: false,
        disablePadding: false,
        label: 'Applicant ID',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'review_answer',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
            {headCells.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'center' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                {/* <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                > */}
                    {headCell.label}
                    {/* {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                    ) : null}
                </TableSortLabel> */}
                </TableCell>
            ))}
            </TableRow>
        </TableHead>
    );
}

function KYCStatus() {
    const [data, setData] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchValue, setSearchValue] = React.useState('');
    const [loading, setLoading] = React.useState(false)
    const [emailStatus, setEmailStatus] = React.useState([]);
    const [radioFilter, setRadioFilter] = React.useState('all');
    const handleRadioFilterChange = (event) => {
        setRadioFilter(event.target.value);
      };
    const getKYCStatusList = async() => {
        setLoading(true)
        try {
            const result = await serverApi.post('/get-all-users-kyc/')
            // console.log(result)
            if(result.data.data) {
                setData(result.data.data.reverse()) 
                setLoading(false)
            }
        }
        catch(err) {
            console.log(err)
            setLoading(false)
        }
    }
    React.useEffect(() => {
        getKYCStatusList()
    },[])
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = data.map((n) => n.name);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const handleSearchValueOnChange = (e) => {
        let inputValue = (e.target.value).trim()
        setSearchValue(inputValue)
    }
    const handleClickClearSearchInput = () => {
        setSearchValue('')
    }
    const handleResendingRegistrationemail = async(email) => {
        setLoading(true)
        let emailSent = [...emailStatus]
        const result = await serverApi.post('/generate-otp/', {email: email})
        // console.log(result)
        if(result.data){
            if(result.data.status == 'success'){
                emailSent.push(email)
                setEmailStatus(emailSent)
            }
        }
        setLoading(false)
    }
    return (
        <Box sx={{ width: '60%' }}>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
                sx={{position: 'absolute',right: '40%', top: 80, height: '15px' }}
            />
            <Paper sx={{ width: '100%', mb: 2, marginTop: 6, paddingTop: 1 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200, margin: 1 }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={searchValue}
                            onChange={(e) => handleSearchValueOnChange(e)}
                            placeholder="Search Email"
                            inputProps={{ 'aria-label': 'search email address' }}
                            endAdornment={searchValue? 
                                <InputAdornment position="end">
                                    <IconButton
                                        size='small'
                                        onClick={handleClickClearSearchInput}
                                        style={{borderRadius: 0}}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment> : <SearchIcon />}
                        />
                    </Paper>
                    <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        // defaultValue="all"
                        value={radioFilter}
                        onChange={handleRadioFilterChange}
                    >
                        <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label="All"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="GREEN"
                            control={<Radio />}
                            label="Verified"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="RED"
                            control={<Radio />}
                            label="Rejected"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="YELLOW"
                            control={<Radio />}
                            label="Pending"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="notinitiated"
                            control={<Radio />}
                            label="Not Started"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </div>
                <TableContainer>
                {loading? <LinearProgress /> : null}
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                        rows.slice().sort(getComparator(order, orderBy)) */}
                    {data.length> 0 && data
                        .filter(item => radioFilter === 'all' ? item : radioFilter === 'notinitiated' ? item.review_answer == null : item.review_answer === radioFilter)
                        .filter(item => searchValue? item.email.toLowerCase().includes(searchValue.toLowerCase()): item)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={index}
                                    selected={isItemSelected}
                                >
                                    <TableCell align='left'>
                                        {row.kyc_applicant_id}
                                    </TableCell>
                                    <TableCell align='left'>
                                        {row.email}
                                    </TableCell>
                                    {/* <TableCell align="left">{row.email}</TableCell> */}
                                    <TableCell align='left'>
                                        {row.review_answer? 
                                            row.review_answer === 'GREEN' ?  
                                            <Chip icon={<CheckCircleOutlineIcon />} color="success" label='VERIFIED'/>
                                            : row.review_answer === 'RED' ? 
                                            <Chip icon={<DangerousOutlinedIcon />} color="error" label='REJECTED'/>
                                            : <Chip icon={<PendingOutlinedIcon />} color="warning" label='PENDING'/>
                                        : <Chip icon={<ErrorOutlineIcon />} label='NOT STARTED'/>}
                                    </TableCell>
                                </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                                >
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export default KYCStatus;